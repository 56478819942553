import { addQueryParameters } from "fieldpro-tools";
import { IList as IBEList } from "fieldpro-tools/dist/src/types";

import apiService from "api/apiService";
import { IList, IListSchema } from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import {
  IGenericQueryToSendToBackend,
  IServerResponse,
} from "redux/actions/appActions";

import { TItemMobileUserLink } from "../subcategories/items/utils/bulkModalUtils";
import { IChangeTagParams } from "./actions";
import { prepareItemsForBackend, prepareListForBackend } from "./utils";

const ITEMS_ENDPOINT = `items`;
const LISTS_ENDPOINT = "lists";
/**
 * Creates a list by making a POST request to api with the given parameters
 * @param {Object} list detail of the list
 * @returns {Promise}
 * */
export type TCreateListFunc = (
  actionName: string,
  list: IList
) => Promise<IServerResponse<{ list_id: string }>>;
export const createListApiCall: TCreateListFunc = (actionName, list) => {
  return apiService.post(
    `${LISTS_ENDPOINT}`,
    prepareListForBackend(list),
    actionName
  );
};

/**
 * Makes an api call to fetch the list of lists
 */
export type TFetchListsByClientResponse = { lists: IBEList[] };
export type TFetchListsByClientFunc = () => Promise<
  IServerResponse<TFetchListsByClientResponse>
>;
export const fetchListsByClientApiCall: TFetchListsByClientFunc = () => {
  return apiService.get(`${LISTS_ENDPOINT}`);
};

/**
 * Makes an api call to fetch the list of lists
 */
export type TFetchCustomerReportsPicturesResponse = {
  customer_reports_pictures: any[]; // TODO: FP-8758 type me
};

export type TFetchCustomerReportsPicturesFunc = (
  customer_id: string,
  query: any // TODO: FP-8758 type me
) => Promise<IServerResponse<TFetchCustomerReportsPicturesResponse>>;
export const fetchCustomerReportsPicturesApiCall: TFetchCustomerReportsPicturesFunc =
  (customer_id: string, query: any = {}) => {
    query.limit = 50;

    const finalUrl = addQueryParameters(
      `customer/${customer_id}/pictures`,
      query
    );
    return apiService.get(finalUrl);
  };

/**
 * Update ObjectList in client
 * @param {String} listId ID of the list we want to update
 * @param {Object} newList new properties of the list
 * */

export type TUpdateListFunc = (
  actionName: string,
  listId: string,
  newList: IList
) => Promise<IServerResponse<{ new_list: any }>>;
export const updateListApiCall: TUpdateListFunc = (
  actionName: string,
  listId,
  newList
) => {
  return apiService.patch(
    `${LISTS_ENDPOINT}/${listId}`,
    prepareListForBackend(newList),
    actionName
  );
};

/**
 * Delete List in client
 * @param {String} listId ID of the list we want to delete
 * @returns {Promise}
 * */
export type TDeleteListFunc = (
  actionName: string,
  listId: string
) => Promise<IServerResponse<{}>>;
export const deleteListApiCall: TDeleteListFunc = (actionName, listId) => {
  return apiService.delete(`${LISTS_ENDPOINT}/${listId}`, {}, actionName);
};

/**
 * Archive List in client
 * @param {String} listId ID of the list we want to archive
 * @returns {Promise}
 * */
export type TArchiveListFunc = (
  actionName: string,
  listId: string
) => Promise<IServerResponse<{}>>;
export const archiveListApiCall: TArchiveListFunc = (actionName, listId) => {
  return apiService.patch(
    `${LISTS_ENDPOINT}/${listId}/archive`,
    {},
    actionName
  );
};

/**
 * Restore List in client
 * @param {String} listId ID of the list we want to restore
 * @returns {Promise}
 * */
export type TRestoreListFunc = (
  actionName: string,
  listId: string
) => Promise<IServerResponse<{}>>;
export const restoreListApiCall: TRestoreListFunc = (
  actionName: string,
  listId
) => {
  return apiService.patch(
    `${LISTS_ENDPOINT}/${listId}/restore`,
    {},
    actionName
  );
};

/**
 * Create Items for a given List
 * @param {String} listId List Id in which we want to create the items
 * @param {Array} items Values for the items (should be conform to the schema designed in the meta)
 * @returns {Promise}
 */
export type TCreateItemsFunc = (
  actionName: string,
  listId: string,
  items: IListItem[],
  listSchema: IListSchema[]
) => Promise<
  IServerResponse<
    {
      id: string;
      _displayed_name: string;
      _displayed_business_id: string;
      /** there can be uploads also */
      [key: string]: string;
    }[]
  >
>;
export const createItemsApiCall: TCreateItemsFunc = (
  actionName: string,
  listId,
  items,
  listSchema
) => {
  return apiService.post(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}`,
    {
      items: prepareItemsForBackend(items, listSchema),
    },
    actionName
  );
};

/**
 * Fetch Items by List Id
 * @param {String} listId ID of the List in which we want the items
 * @returns {Promise}
 */
export type TFetchItemsByListFunc = (
  listId: string
) => Promise<IServerResponse<any>>;
export const fetchItemsByListApiCall: TFetchItemsByListFunc = (listId) => {
  return apiService.get(`${ITEMS_ENDPOINT}/${listId}`);
};

/**
 * Update Items
 * @param {Array} items Items to edit (with their id and all the new properties to change)
 * @param {String} listId ID of the list we want to update
 * @returns {Promise}
 */
export type TUpdateItemsFunc = (
  actionName: string,
  items: IListItem[],
  listId: string,
  listSchema: IListSchema[],
  attributeKeysToConsider: string[] | undefined
) => Promise<IServerResponse<any>>;
export const updateItemsApiCall: TUpdateItemsFunc = (
  actionName: string,
  items,
  listId,
  listSchema,
  attributeKeysToConsider
) => {
  return apiService.patch(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}`,
    {
      items: prepareItemsForBackend(items, listSchema),
      attribute_keys_to_consider: attributeKeysToConsider,
    },
    actionName
  );
};

/**
 * Upload image
 * @param {Array} files file objects to upload
 * @param {Array} file_metas array of meta info to give to the backend for each files
 * @returns {Promise}
 */
export type TUploadFileFunc = (
  actionName: string,
  files: any,
  file_metas: any
) => Promise<IServerResponse<{ uploaded: boolean }>>;
export const uploadFileApiCall: TUploadFileFunc = (
  actionName: string,
  files,
  file_metas
) => {
  return apiService.multipartUpdate(
    `/upload/items`,
    files,
    { file_metas },
    actionName
  );
};

/**
 * Delete Items
 * @param {String} listId List Id in which we want to delete the items
 * @param {String[]} itemIds IDs of the items we want to delete
 * @returns {Promise}
 */
export type TDeleteItemsFunc = (
  actionName: string,
  listId: string,
  itemIds: string[]
) => Promise<IServerResponse<{}>>;
export const deleteItemsApiCall: TDeleteItemsFunc = (
  actionName,
  listId,
  itemIds
) => {
  return apiService.delete(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}`,
    {
      ids: itemIds,
    },
    actionName
  );
};

/**
 * Archive Items
 * @param {String} listId List Id in which we want to archive the items
 * @param {String[]} itemIds IDs of the items we want to archive
 * @returns {Promise}
 */
export type TArchiveItemsFunc = (
  actionName: string,
  listId: string,
  itemIds: string[]
) => Promise<IServerResponse<{}>>;
export const archiveItemsApiCall: TArchiveItemsFunc = (
  actionName: string,
  listId,
  itemIds
) => {
  return apiService.patch(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/archive`,
    {
      ids: itemIds,
    },
    actionName
  );
};

/**
 * Restore Items
 * @param {String} listId List Id in which we want to restore the items
 * @param {String[]} itemIds IDs of the items we want to restore
 * @returns {Promise}
 */
export type TRestoreItemsFunc = (
  actionName: string,
  listId: string,
  itemIds: string[]
) => Promise<IServerResponse<{}>>;
export const restoreItemsApiCall: TRestoreItemsFunc = (
  actionName: string,
  listId,
  itemIds
) => {
  return apiService.patch(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/restore`,
    {
      ids: itemIds,
    },
    actionName
  );
};

/**
 * Assign Items
 * @param {String} links Detail of the new links we want
 * @returns {Promise}
 */
export type TAssignItemsFunc = (
  actionName: string,
  listId: string,
  links: (
    | {
        item_id: string;
        mobile_user_id: string;
        team_id?: undefined;
      }
    | {
        item_id: string;
        team_id: string;
        mobile_user_id?: undefined;
      }
  )[]
) => Promise<IServerResponse<{}>>;
export const assignItemsApiCall: TAssignItemsFunc = (
  actionName: string,
  listId,
  links
) => {
  return apiService.patch(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/assign`,
    {
      links,
    },
    actionName
  );
};

/**
 * Unassign Items
 * @param {String} ids Id's of the new links we want
 * @returns {Promise}
 */
export function unassignItemsApiCall(
  actionName: string,
  listId: string,
  ids: string[]
) {
  return apiService.patch(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/unassign`,
    { ids },
    actionName
  );
}

/**
 * Unassign specify mobile users to items
 * @param {String} mobileUsersIds Id's of the mobile users we want unassign
 * @returns {Promise}
 */
export function unassignMobileUsersApiCall(
  actionName: string,
  listId: string,
  links: TItemMobileUserLink[]
) {
  return apiService.patch(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/unassign_mobile_users`,
    { links },
    actionName
  );
}

/**
 * Fetch Items per list
 * @param {String} listId ID of the list we want to fetch the items from
 * @returns {Promise}
 */
export type TFetchItemsForListFunc = (
  listId: string,
  query: IGenericQueryToSendToBackend
) => Promise<
  IServerResponse<{
    items: any[];
    item_count: number;
  }>
>;

export const fetchItemsForListApiCall: TFetchItemsForListFunc = async (
  listId,
  query
) => {
  const finalUrl = addQueryParameters(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}`,
    query
  );
  return apiService.get(finalUrl);
};

export const downloadQRCodesApiCall: TDownloadListItemsFunc = (
  listId,
  query
) => {
  const finalUrl = addQueryParameters(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/qrcode`,
    query
  );
  return apiService.get(finalUrl, undefined, undefined, true);
};

export type TDownloadListItemsFunc = (
  listId: string,
  query: IGenericQueryToSendToBackend
) => Promise<IServerResponse<any>>;
export const downloadListItemsApiCall: TDownloadListItemsFunc = (
  listId,
  query
) => {
  const finalUrl = addQueryParameters(
    `${LISTS_ENDPOINT}/${listId}/${ITEMS_ENDPOINT}/download`,
    query
  );
  return apiService.get(finalUrl, undefined, undefined, true);
};

export type TEditAttributeTagFunc = (
  listId: string,
  params: IChangeTagParams
) => Promise<IServerResponse<{}>>;
export const editAttributeTagApiCall: TEditAttributeTagFunc = (
  listId,
  params
) => {
  return apiService.patch(`${LISTS_ENDPOINT}/${listId}/changetag`, {
    ...params,
  });
};

export type TGetListOptionsFunc = (
  listId: string,
  query?: IGenericQueryToSendToBackend
) => Promise<
  IServerResponse<{
    options: {
      id: string;
      name: string;
    }[];
  }>
>;

export const getListOptionsApiCall: TGetListOptionsFunc = (listId, query) => {
  const url = addQueryParameters(
    `${LISTS_ENDPOINT}/${listId}/options`,
    query ?? {}
  );
  return apiService.get(url);
};
