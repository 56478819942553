import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";

import InputSearch from "components/Input/InputSearch";
import DynamicallyRenderedMenuList from "components/Map/DynamicalyRenderedMenuList";
import CustomSpinner from "components/Progress/CustomSpinner";
import { useTranslations } from "hooks/useTranslations";
import { IOption } from "model/application/components";

import { IFilter } from "../../../../model/application/Filter";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

/**
 * A generic component for filtering multiple choice options on any target endpoint (list,dashboards,etc...)
 * to be wrapped by a filter component that needs to fetch its options from the BE
 * look at MultipleChoiceOnListFilter for an example
 */
export interface IBaseMultipleChoiceOnOptionsProps {
  filter: IFilter;
  onChangeFilter: (name: string, value: any) => any;
  searchDebounceDuration?: number;
  loading?: boolean;
}
const BaseMultipleChoiceOnOptions: React.FC<
  IBaseMultipleChoiceOnOptionsProps
> = ({
  filter,
  onChangeFilter,
  loading = false,
  searchDebounceDuration = 500,
}) => {
  const defaultValue: IOption<string>[] = filter.value || [];
  const [checkedOptions, setCheckedOptions] =
    useState<IOption<string>[]>(defaultValue);
  const [listedOptions, setListedOptions] = useState<IOption<string>[]>(
    getOptions()
  );
  const lang = useTranslations();
  const classes = useStyles();

  async function handleSearch(searchTerm: string) {
    if (filter.onSearch) {
      await filter.onSearch(searchTerm);
    }
  }

  function handleCheckOption(event: React.ChangeEvent<HTMLInputElement>) {
    if (filter.singleSelection && checkedOptions.length > 0) {
      return;
    }
    const { value } = event.target;
    const option = _.find(listedOptions, { key: value });
    if (option) {
      setCheckedOptions([...checkedOptions, option]);
    }
  }
  function handleUnCheckOption(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const option = _.find(checkedOptions, { key: value });
    if (option) {
      setCheckedOptions(checkedOptions.filter((o) => o.key !== value));
    }
  }

  function handleAppy() {
    onChangeFilter(filter.tag, checkedOptions);
  }

  useEffect(() => {
    if (filter.options) {
      setListedOptions(getOptions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filter.options ?? [])]);

  function getOptions() {
    return _.uniqBy(_.concat(checkedOptions, filter.options ?? []), "key");
  }

  return (
    <div className={classes.DropdownItem}>
      <FormControl component="fieldset" className={classes.Bottom}>
        <InputSearch
          dataTestId="search"
          onChange={_.debounce(handleSearch, searchDebounceDuration)}
        />

        <Box
          margin="12px 0"
          display="grid"
          flexDirection="column"
          maxHeight={"300px"}
          paddingBottom={"20px"}
          overflow="auto"
          data-testid={"checkbox-container"}
          width={"350px"}
          style={{ overflowX: "hidden" }}
        >
          {loading ? (
            <Box
              width={"100%"}
              alignContent={"center"}
              justifyContent={"center"}
              display={"grid"}
              padding={"16px"}
            >
              <CustomSpinner size={"30px"} />
            </Box>
          ) : (
            <DynamicallyRenderedMenuList
              Component={React.Fragment}
              buildMenuItemFunction={(index) => {
                const option = listedOptions[index];
                if (!option) return null;

                const { key, label } = option;
                const isChecked = !_.isEmpty(_.find(checkedOptions, { key }));
                return (
                  <FormControlLabel
                    key={option.key}
                    label={label}
                    control={
                      <Checkbox
                        className={classes.RadioCustom}
                        value={key}
                        onChange={
                          isChecked ? handleUnCheckOption : handleCheckOption
                        }
                        checked={isChecked}
                      />
                    }
                    className={classNames(
                      classes.RadioButtonsCustom,
                      classes.label
                    )}
                    data-testid={key}
                    name={label}
                  />
                );
              }}
              listSize={listedOptions.length}
              maxItems={50}
            />
          )}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Button
            className={classes.ClearSelectionButton}
            onClick={() => {
              setCheckedOptions([]);
            }}
          >
            {lang.components.filters.clearSelection}
          </Button>
          <Button
            data-testid="apply"
            variant="outlined"
            color="primary"
            className={classes.FilterButton}
            onClick={handleAppy}
          >
            <span className={classes.FilterButtonLabel}>
              {lang.modal.apply}
            </span>
          </Button>
        </Box>
      </FormControl>
    </div>
  );
};
export default BaseMultipleChoiceOnOptions;
