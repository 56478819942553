import { DashboardsState } from "containers/dashboards/redux/reducer";
import { IDbSchemaState } from "containers/environments/redux/dbSchema/reducer";
import { ITableOptimizationState } from "containers/environments/redux/tableOptimizations/reducer";
import { IFoldersStates } from "containers/folders/redux/reducer";
import { ListsState } from "containers/lists/redux/reducer";
import { TeamsState } from "containers/teams/redux/reducer";
import { TerritoryState } from "containers/territories/redux/reducer";
import { WorkflowsState } from "containers/workflows/redux/reducer";
import { IHttpRequestState } from "containers/workflows/subcategories/jobs/redux/http_requests/reducer";
import { IIntegrationState } from "containers/workflows/subcategories/jobs/redux/integrations/reducer";
import { INotificationState } from "containers/workflows/subcategories/jobs/redux/notifications/reducer";
import { JobsState } from "containers/workflows/subcategories/jobs/redux/reducer";
import { IJobScriptState } from "containers/workflows/subcategories/jobs/redux/scripts/reducer";
import { IStreamState } from "containers/workflows/subcategories/jobs/redux/streams/reducer";
import { ITransformationState } from "containers/workflows/subcategories/jobs/redux/transformations/reducer";

import { IMetaExpressionsState } from "./../containers/workflows/redux/meta_expressions/reducer";

/**
 * Converts an array of items to an object with the id's used as lookup keys.
 * This speads up the search for items in the redux store using ids
 * @param {Object} object Object to update. Will copy over items from newly created object to this object
 * @param {Array} array Array with items to update. this will reduce the array of objects to an object with the ids
 * as lookup values
 * @param {String} keyField The field to use as the lookup value
 * @returns {Object} an object with the objects from array
 */
export const convertArrayToObject = (
  object: any,
  array: any[],
  keyField: string
) => {
  const objUpdate = array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});
  return {
    ...object,
    ...objUpdate,
  };
};

/**
 * Inserts a new item to an array given the array action object
 * @param {Array} array array to insert item
 * @param {Object} item Object to insert to array
 * @returns {Array} New Array Item with the updated action Object
 * */
export const insertNewItemToArr = (array: any[], item: any) => {
  const newArray = array.slice();
  newArray.splice(array.length, 0, { ...item });
  return newArray;
};

/**
 * Updates items in array with new array. copies over items from array with new objects onto array
 * with initial objects.
 * @param {Array} array Array with objects to update
 * @param {Array} items Array with new data
 * @returns {Array}
 * */
export const updateItemsInArray = (array: any[], items: any[]) => {
  const newArray = array.slice();
  return newArray.concat(items).filter((obj, pos, arr) => {
    return arr.map((item) => item["id"]).indexOf(obj["id"]) === pos;
  });
};

/**
 * Removes multiple objects from array given that the items exist in the given array
 * @param {Array} array Array of items which is a superset of items to be removed
 * @param {Array} items Items to remove from array
 * @return {Array} new Array with which the subset of items have been removed from array
 */
export const removeObjectsFromArray = (array: any[], items: any[]) => {
  let newArray: any[] = [];
  items.forEach((item) => {
    newArray = removeItem(array, item);
  });

  return newArray;
};

/**
 * Removes an item from the array
 * @param {Array} array Array to remove item from
 * @param {Object} item Action object which is to be deleted from the array
 * @returns {Array}
 * */
export const removeItem = (array: any[], item: any) => {
  const newArray = array.slice();
  newArray.splice(item.id, 1);
  return newArray;
};

/**
 * Removes an item from the array given their id
 * @param {Array} array Array to update
 * @param {String} id User id to delete from array
 * @return {Array} new updated array
 * */
export const removeObjectFromArray = (array: any[], id: string) => {
  return array.filter((item) => item.id !== id);
};

/**
 * Remove Object from the given Object with the passed in property
 * iterate over the given keys array and reduce with the given accumulator and key value
 * if the key(currentValue) is not the property we seek, ignore it and add the object to the
 * accumulator, else return the accumulator without the given object to delete, the initialValue is the object
 * we are copying over items to
 * @param {Object} obj Object to update
 * @param {String} property Key to use to remove the object from the hash table
 * @returns {Object} Returns the updated object
 */
export const removeObjectFromHash = (obj: any, property: any) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (key !== property) {
      return { ...acc, [key]: obj[key] };
    }
    return acc;
  }, {});
};

/**
 * Updates object in array given the array and the object. This returns a newly updated object in the array.
 * If the object is not in the array. Then the same array is returned.
 * @param {Array} array The array in which the object exists for an update
 * @param {Object} obj Action object to extract information on the object to perform the update
 * @returns {Array} Newly updated Array with the updated object
 * */
export const updateObjectInArray = (array: any[], obj: any) => {
  return array.map((item) => {
    if (item.id !== obj.id) {
      // we do not care for this item, we return it
      return item;
    }

    // otherwise, this is the item to update, we copy over the updated information onto it
    return {
      ...item,
      ...obj,
    };
  });
};

// TODO: fix the error updater
/**
 * Updates the errors list in the Redux Store
 * @param {Array} errArray Where the errors are stored
 * @param {Object} error Object with error object from dispatch
 */
export const updateErrorsList = (
  state:
    | DashboardsState
    | TeamsState
    | WorkflowsState
    | ListsState
    | TerritoryState
    | INotificationState
    | IIntegrationState
    | ITransformationState
    | ITableOptimizationState
    | IJobScriptState
    | IStreamState
    | JobsState
    | IMetaExpressionsState
    | IDbSchemaState
    | IFoldersStates
    | IHttpRequestState,
  error: any
) => {
  const err = {
    ...error.error,
    type: error.type,
  };
  return state.errors.length === 0
    ? insertNewItemToArr(state.errors, err)
    : updateObjectInArray(state.errors, err);
};

/**
 * Extracts all ids from an array with objects, provided each object has an id property.
 * If the item does not have an id property, the index is used instead
 * @param {Array} array
 * @returns {Array}
 */
export const extractIdsFromArray = (array: any[]): string[] => {
  return array.map((item, index) => {
    if (item.hasOwnProperty("id")) {
      return item.id;
    } else {
      return index;
    }
  });
};
